@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;500;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
:root {
  --main-bg-color: red;
}

* {
  font-family: 'Open Sans', sans-serif !important;
}

.chakra-button:focus, a {
  outline: none !important;
  box-shadow: none !important;
}

.top-nav-item {
  background-color: transparent !important;
  border-bottom: 4px solid #fff;
  border-radius: 0px !important;
  font-size: 15px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 4.27 !important;
  letter-spacing: 2px !important;
  text-transform: uppercase;
}

.top-nav-item:hover {
  border-bottom: 4px solid red;
  border-bottom: 4px solid var(--main-bg-color);
  color: red;
  color: var(--main-bg-color);
}

.top-nav-image, .image-gallery-image {
  /* height: 800px !important; */
  height: 700px !important;
  width: 100% !important;
  object-fit: cover !important;
}

.noto-font {
  font-family: 'Noto Serif', serif !important;
}

.zoom-hover {
  transition: transform .3s ease;
}

.zoom-hover:hover {
  transform: scale(1.05);
}

.heading-dot::after {
  /* content: ".";
  color: var(--main-bg-color); */
}

.grid-img {
  object-fit: cover;
  min-height: 100%;
}

.is-active {
  border-bottom: 4px solid red;
  border-bottom: 4px solid var(--main-bg-color);
  color: red;
  color: var(--main-bg-color);
}

.object-fit-cover {
  object-fit: cover;
}

.h-400 {
  height: 400px !important;
}

.h-600 {
  height: 600px !important;
}

.clear-both {
  clear: both !important;
}

.ReactGridGallery {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 1rem;
}

.ReactGridGallery_tile-viewport {
  width: 100% !important;
}

.ReactGridGallery_tile-viewport img {
  width: 100% !important;
  margin: 0 !important;
  object-fit: cover;
}

.centeredSliderText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.phone-btn svg {
  margin: 0 auto !important;
}

.w-100 {
  width: 100% !important;
}

.ReactGridGallery_tile-viewport {
  min-height: 350px !important;
}

.ReactGridGallery_tile-viewport img {
  height: 100% !important;
}

.blurred {
  filter: blur(4px);
  -webkit-filter: blur(4px)
}
